import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { Typography } from '@mui/material';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText, SearchSelect } from '#Search'
import DataList from '#DataList'
import Fab from '#Fab'
import { ModImpegnoDialog } from './ModImpegnoEdit'
import { OfferDetailsDialog } from './OfferDetails'
import { DisplayDate } from '#Display'

import {
	faPlus,
	faEdit,
	faEye,
	faEyeSlash,
	faShoppingBag,
	faHourglass,
	faTruckRampBox,
	faMagnifyingGlass,
	faRotateBack,
	faCabinetFiling,
} from '@fortawesome/pro-regular-svg-icons'

const statusTypes = [
	{ value:'all', label:'Tutti' },
	{ value:'active', label:'Attivi' },
	{ value:'closed', label:'Chiusi' },
]

const ComponentRequest = ({ _id }) => {
	const api = useContext(APICtx)
	const [ component, setComponent ] = useState({})

	const handleRefresh = () => api.call('workflow/component-req/get', { _id }).then(setComponent)
	useEffect(() => {
		handleRefresh()
	}, [ _id ])

	return (
		<>
			{ component?.customer?.label && <Typography variant="body2">{component.customer.label}</Typography> }
			<DisplayDate value={component?.customerRequest?.date} />
		</>
	)
}

const useTableDef = () => {
	const api = useContext(APICtx)
	const def = [
		{ label:"Richiesta cliente", content:row => <ComponentRequest _id={row.componentRequest} /> },
	]
	if(api.auth.chk('acquisti'))
		def.push({ label:"Fornitore", content:row => row.supplier.label })
	def.push(
		{ label:"Articolo", field:'ware' },
		{ label:"Marca", field:'brand' },
	)
	if(api.auth.chk('acquisti'))
		def.push({
			label: "Passare",
			field: 'isAvailable',
			type: 'boolean',
		})
	def.push(
		{
			label: "Offerte fornitori",
			type: 'icon',
			content: row =>
				row.isAccepted ? faShoppingBag : (row.isRefused ? faCabinetFiling : faHourglass),
			tooltip: row =>
				row.isAccepted ? 'Offerta accettata' : (row.isRefused ? 'Offerta rifiutata' : 'Offerta in valutazione'),
		},
		{ label:"Mod. impegnato", type:'boolean', field:'isRequested' },
		{ label:"Costo", field:'cost' },
		{ label:"Data consegna", field:'deliveryDate' },
	)
	if(api.auth.chk('acquisti'))
		def.push({ label:"Note", field:'notes', type:'multiline' })
	return def
}

export default function SupplierList() {
	const api = useContext(APICtx)
	const { componentRequest, customerRequest } = useParams()
	const [ ids, setIds ] = useState([])
	const [ searchString, setSearchString ] = useState('')
	const [ searchSupplier, setSearchSupplier ] = useState('')
	const [ searchStatus, setSearchStatus ] = useState(null)
	const [ checkboxes, setCheckboxes ] = useState([])
	const [ modImpegno, setModImpegno ] = useState(false)
	const [ showDetails, setShowDetails ] = useState(false)
	const [ component, setComponent ] = useState(false)
	const tableDef = useTableDef()
	const inFullList = !componentRequest && !customerRequest

	const handleNewModImpegno = () => {
		console.log('MOD IMP', checkboxes)
		setModImpegno(true)
	}

	const handleAvailabilityToggle = ({ _id, isAvailable, handleRowRefresh }) =>
		api.call('workflow/supplier/set-availability', { _id, isAvailable:!isAvailable })
			.then(() => {
				handleRowRefresh()
				handleRefresh()
			})
	const handleAcceptedToggle = ({ _id, isAccepted, handleRowRefresh }) => {
		const callPath = 'workflow/supplier/' + (isAccepted ? 'unset' : 'set') + '-accepted'
		api.call(callPath, { _id })
			.then(() => {
				handleRowRefresh()
				handleRefresh()
			})
	}
	const handleRefusedToggle = ({ _id, isRefused, handleRowRefresh }) => {
		const callPath = 'workflow/supplier/' + (isRefused ? 'unset' : 'set') + '-refused'
		api.call(callPath, { _id })
			.then(() => {
				handleRowRefresh()
				handleRefresh()
			})
	}
	// const handleDelete = ({ _id, supplier }) => {
	// 	api.dialogConfirm({
	// 		text: 'Sei sicuro di voler cancellare la proposta del fornitore "' + supplier + '"?',
	// 		onConfirm: () => api.call('workflow/supplier/remove', { _id }).then(handleRefresh),
	// 	})
	// }
	const handleRefresh = () => api.call('workflow/supplier/list', { searchString, searchSupplier, searchStatus, componentRequest })
		.then(setIds)
	useEffect(() => {
		(componentRequest || searchStatus!==null) && handleRefresh()
	}, [ searchString, searchSupplier, searchStatus, componentRequest ])
	useEffect(() => {
		componentRequest ? api.call('workflow/component-req/get', { _id:componentRequest }).then(setComponent) : setComponent(null)
	}, [ componentRequest ])

	const getRow = _id => api.call('workflow/supplier/get', { _id })
	const defBtns = [
		{
			show: row => !row.isAccepted && api.auth.chk('acquisti'),
			icon: row => row.isAvailable ? faEyeSlash : faEye,
			label: row => (row.isAvailable ? 'Disabilita' : 'Abilita') + ' ai venditori',
			onClick: handleAvailabilityToggle,
		},
		{
			show: row => row.isAvailable && !row.isRefused && !row.isRequested && api.auth.chk('vendite'),
			icon: row => row.isAccepted ? faRotateBack : faShoppingBag,
			label: row => (!row.isAccepted ? 'Accetta' : 'Annulla accettazione') + ' proposta',
			onClick: handleAcceptedToggle,
		},
		{
			show: row => row.isAvailable && !row.isAccepted && !row.isRequested && api.auth.chk('vendite'),
			icon: row => row.isRefused ? faRotateBack : faCabinetFiling,
			label: row => (!row.isRefused ? 'Rifiuta' : 'Annulla rifiuto') + ' proposta',
			onClick: handleRefusedToggle,
		},
		{
			icon: faMagnifyingGlass,
			label: 'Dettagli offerta',
			onClick: setShowDetails,
		},
		{
			show: row => !row.isAvailable && api.auth.chk('acquisti'),
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => customerRequest ?
				'/workflow/supplier/customer-edit/'+customerRequest+'/'+componentRequest+'/'+row._id : (
					componentRequest ?
					'/workflow/supplier/component-edit/'+componentRequest+'/'+row._id :
					'/workflow/supplier/edit/'+row._id
				),
		},
		// {
		// 	show: row => !row.isAvailable && !row.isRefused && !row.isRequested && api.auth.chk('acquisti'),
		// 	icon: faTrash,
		// 	label: 'Elimina',
		// 	onClick: handleDelete,
		// },
	]
	const listCheckboxes = (inFullList && api.auth.chk('vendite')) ? (row => row.isAccepted && !row.isRequested) : undefined

	const backTo = customerRequest ?
		'/workflow/component/customer-list/'+customerRequest :
		(componentRequest ? '/workflow/component/list' : undefined)
	const createLink = customerRequest ?
		'/workflow/supplier/customer-edit/'+customerRequest+'/'+componentRequest :
		(componentRequest ? '/workflow/supplier/component-edit/'+componentRequest : undefined)
	const title = (component?.customer?.label ? component.customer.label+' - ' : '') + 'Proposte fornitori'
	return (
		<Tpl title={title} backTo={backTo}>
			<OfferDetailsDialog data={showDetails} handleClose={() => setShowDetails(false)} />
			<ModImpegnoDialog data={Boolean(modImpegno)} closeEdit={() => setModImpegno(false)} checkboxes={checkboxes} handleRefresh={handleRefresh} />
			<Search>
				<SearchText label="Cerca marca articolo" name="src-string" onChange={setSearchString} />
				<SearchText label="Cerca fornitore" name="src-supplier" onChange={setSearchSupplier} />
				{ inFullList && <SearchSelect name="src-status" onChange={setSearchStatus} label="Stato" options={statusTypes} defaultValue="active" /> }
			</Search>
			<DataList
				def={tableDef}
				rowActions={defBtns}
				ids={ids}
				getRow={getRow}
				pageSize={50}
				listCheckboxes={listCheckboxes}
				setCheckboxes={setCheckboxes}
			/>
			{ Boolean(createLink) && api.auth.chk('acquisti') && (
				<Fab label="Crea una proposta fornitore" icon={faPlus} linkTo={createLink} />
			)}
			{ inFullList && api.auth.chk('vendite') && (
				<Fab label="Crea un modulo impegno" icon={faTruckRampBox} onClick={handleNewModImpegno} />
			)}
		</Tpl>
	)
}
