import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Search, { SearchText, SearchSelect } from '#Search'

import { faEdit, faTrash, faListCheck, faCheck, faSlash } from '@fortawesome/pro-regular-svg-icons'
import { ModImpegnoDialog } from './ModImpegnoEdit'

const statusTypes = [
	{ value:'all', label:'Tutti' },
	{ value:'active', label:'Attivi' },
	{ value:'closed', label:'Chiusi' },
]

const useTableDef = () => {
	return [
		{ label:"Numero ordine", content:row => row?.orderNumber },
		{ label:"Confermato", field:'isConfirmedRequest', type:'boolean' },
		{ label:"Ordinato", field:'isConfirmedOrder', type:'boolean' },
		{ label:"Impegnato", field:'isConfirmedCommitment', type:'boolean' },
		{ label:"Cliente", content:row => row?.customer?.label },
		{ label:"Data richiesta", field:'dateRequested' },
		{
			content: row => row?.isFixed ? faEdit : undefined,
			tooltip: 'Modificato dopo la conferma',
			type: 'icon',
		},
	]
}

export default function ModImpegnoList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchString, setSearchString ] = useState('')
	const [ searchStatus, setSearchStatus ] = useState(null)
	const [ editModule, setEditModule ] = useState(null)
	const tableDef = useTableDef()

	const handleEdit = row =>
		setEditModule(row)
	const handleDelete = ({ _id, orderNumber }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il modulo "' + orderNumber + '"?',
			onConfirm: () => api.call('workflow/mod-impegno/remove', { _id }).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('workflow/mod-impegno/list', { searchString, searchStatus }).then(setIds)
	
	// TOGGLE FLAGS
	const handleConfirmRequest = ({ _id, orderNumber, handleRowRefresh }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler confermare il modulo "' + orderNumber + '"?',
			onConfirm: () => api.call('workflow/mod-impegno/set-confirmed-request', { _id })
				.then(() => {
					handleRowRefresh()
					handleRefresh()
				}),
		})
	const handleConfirmOrder = ({ _id, isConfirmedOrder, handleRowRefresh }) => {
		const callPath = 'workflow/mod-impegno/' + (isConfirmedOrder ? 'unset' : 'set') + '-confirmed-order'
		api.call(callPath, { _id })
			.then(() => {
				handleRowRefresh()
				handleRefresh()
			})
	}
	const handleConfirmCommitment = ({ _id, isConfirmedCommitment, handleRowRefresh }) => {
		const callPath = 'workflow/mod-impegno/' + (isConfirmedCommitment ? 'unset' : 'set') + '-confirmed-commitment'
		api.call(callPath, { _id })
			.then(() => {
				handleRowRefresh()
				handleRefresh()
			})
	}

	useEffect(() => {
		searchStatus!==null && handleRefresh()
	}, [ searchString, searchStatus ])

	const getRow = _id => api.call('workflow/mod-impegno/get', { _id })
	const defBtns = [
		{
			show: row => (
				// (!row.isConfirmedRequest && api.auth.chk('vendite')) ||
				// (row.isConfirmedRequest && api.auth.chkAny([ 'acquisti', 'customer-service']))
				true //FIXME a tutti perché in caso blocco dal backend
			),
			icon: faEdit,
			label: 'Modifica',
			onClick: handleEdit,
		},
		{
			icon: faListCheck,
			label: 'Componenti richiesti',
			linkTo: row => '/workflow/commitment-form/row/'+row._id+'/list',
		},
		{
			show: api.auth.chk('superadmin'),
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
		{
			show: row => Boolean(!row.isConfirmedRequest && api.auth.chk('vendite')),
			icon: faCheck,
			label: 'Conferma definitivamente il modulo',
			onClick: handleConfirmRequest,
		},
		{
			show: row => Boolean(row.isConfirmedRequest && api.auth.chk('acquisti')),
			icon: faCheck,
			icon2: row => row.isConfirmedOrder && faSlash,
			label: row => (!row.isAccepted ? 'Conferma' : 'Annulla conferma') + ' ordine',
			onClick: handleConfirmOrder,
		},
		{
			show: row => Boolean(row.isConfirmedRequest && api.auth.chk('customer-service')),
			icon: faCheck,
			icon2: row => row.isConfirmedCommitment && faSlash,
			label: row => (!row.isAccepted ? 'Conferma' : 'Annulla conferma') + ' impegno',
			onClick: handleConfirmCommitment,
		},
	]

	return (
		<Tpl title='Moduli impegno'>
			<ModImpegnoDialog data={editModule} closeEdit={() => setEditModule(null)} handleRefresh={handleRefresh} />
			<Search>
				<SearchText label="Cerca numero / cliente" name="src-string" onChange={setSearchString} />
				<SearchSelect name="src-status" onChange={setSearchStatus} label="Stato" options={statusTypes} defaultValue="active" />
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={50} />
		</Tpl>
	)
}
