import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'

import { faPlus, faTrash, faEdit, faTruckClock } from '@fortawesome/pro-regular-svg-icons'
import { OfferDetailsDialog } from './OfferDetails'

const useTableDef = () => {
	const api = useContext(APICtx)
	const def = [
		{ label:"Descrizione", field:'description' },
		{ label:"Quantità B.A.", field:'quantity' },
		{ label:"Marca", field:'brand' },
		{ label:"Quantità impegnata", field:'committed', },
		{ label:"Prezzo", field:'price' },
		{ label:"Sconto", field:'discount' },
		{ label:"Data consegna", field:'dateDelivery' },
	]
	if(api.auth.chk('acquisti'))
		def.push(
			{ label:"Fornitore", content:row => row?.reqSupplier?.supplierLabel },
			{ label:"Note risp. fornitore", content:row => row?.reqSupplier?.notes, type:'multiline' },
		)
	return def
}
export default function CustomerList() {
	const api = useContext(APICtx)
	const { reqModuleId } = useParams()
	const [ module, setModule ] = useState({})
	const [ ids, setIds ] = useState([])
	const [ showDetails, setShowDetails ] = useState(false)
	const tableDef = useTableDef()

	const handleDelete = ({ _id, description }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il componente "' + description + '"?',
			onConfirm: () => api.call('workflow/mod-impegno/row/remove', { _id }).then(handleRefresh),
		})
	const handleShowDetails = async ({ reqSupplierId }) =>
		api.call('workflow/supplier/get', { _id:reqSupplierId })
			.then(setShowDetails)
	const aclRW = (!module.isConfirmedRequest && api.auth.chk('vendite')) || (module.isConfirmedRequest && api.auth.chkAny([ 'acquisti', 'customer-service']))

	const handleRefresh = () => api.call('workflow/mod-impegno/row/list', { reqModuleId }).then(setIds)
	useEffect(() => {
		api.call('workflow/mod-impegno/get', { _id:reqModuleId }).then(setModule)
		handleRefresh()
	}, [])

	const getRow = _id => api.call('workflow/mod-impegno/row/get', { _id })

	const defBtns = [
		{
			show: aclRW,
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/workflow/commitment-form/row/'+reqModuleId+'/edit/' + row._id,
		},
		{
			show: row => row.reqSupplierId && api.auth.chk('acquisti'),
			icon: faTruckClock,
			label: 'Dettagli offerta',
			onClick: handleShowDetails,
		},
		{
			show: aclRW,
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	]

	const title =
		(module?.customer?.label ? module.customer.label+' - ' : '' ) +
		(module?.orderNumber ? '#'+module.orderNumber+' - ' : '' ) +
		'Dettaglio'
	const backTo = '/workflow/commitment-form/list'
	return (
		<Tpl title={title} backTo={backTo}>
			<OfferDetailsDialog data={showDetails} handleClose={() => setShowDetails(false)} />
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={50} />
			{ aclRW && <Fab label="Aggiungi componente" icon={faPlus} linkTo={'/workflow/commitment-form/row/'+reqModuleId+'/edit'} /> }
		</Tpl>
	)
}
