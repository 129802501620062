import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Autocomplete } from '#Form'

const PREFIX_NEW = '[NUOVO] '

export default function SupplierEdit() {
	const api = useContext(APICtx)
	const { _id, customerRequest, componentRequest } = useParams()
	const [ componentData, setComponentData ] = useState({})

	const aclRW = api.auth.chk('acquisti')

	const handleRefresh = async () => {
		let evalComponentId = componentRequest
		if(!evalComponentId && _id) {
			const evalSupplier = await api.call('workflow/supplier/get', { _id })
			evalComponentId = evalSupplier?.componentRequest
		}
		if(evalComponentId)
			await api.call('workflow/component-req/get', { _id:evalComponentId }).then(setComponentData)
	}
	useEffect(() => {
		!_id && componentRequest && api.call('workflow/component-req/get', { _id:componentRequest }).then(setComponentData)
		handleRefresh()
	}, [ _id, componentRequest ])
	const autocompleteSupplier = async searchString => {
		const newLabel = (searchString?.startsWith(PREFIX_NEW) ? '' : PREFIX_NEW) + searchString

		const skipList = searchString?.startsWith(PREFIX_NEW) || !searchString?.length || searchString.length < 3
		const options = skipList ? [] : await api.call('supplier/options', { searchString })
		searchString?.length && !options.find(item => item.label===newLabel) && options.push({
			value: newLabel,
		})
		return options
	}

	const formDef = {
		_id,
		callSet: aclRW && (_id ? "workflow/supplier/update" : "workflow/supplier/create"),
		callGet: _id && "workflow/supplier/get",
		extraData: { componentRequest },
	}

	const title = componentData?.description + ' - ' + (_id ? 'Modifica' : 'Crea') + ' risp. fornitore'
	const backTo = customerRequest ?
		'/workflow/supplier/customer-list/'+customerRequest+'/'+componentRequest : (
			componentRequest ?
				'/workflow/supplier/component-list/'+componentRequest :
				'/workflow/supplier/list'
		)
	return (
		<Tpl title={title} backTo={backTo}>
			<Form {...formDef}>
				{ aclRW && <Autocomplete label="Fornitore" name="supplier" required getOptions={autocompleteSupplier} disabled={_id} /> }
				<Input label="Articolo" name="ware" disabled={!aclRW} />
				<Input label="Marca" name="brand" disabled={!aclRW} />
				<Input label="Costo" name="cost" disabled={!aclRW} />
				<Input label="Data consegna" name="deliveryDate" disabled={!aclRW} />
				{ aclRW && <Input label="Note" name="notes" multiline disabled={!aclRW} /> }
			</Form>
		</Tpl>
	)
}
